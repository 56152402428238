import React from "react"

function TwoCol(props) {
  const { content } = props
  const {
    acf_fc_layout,
    project_story_2_column_layout_image_left,
    project_story_2_column_layout_description_left,
    project_story_2_column_layout_image_right,
    project_story_2_column_layout_description_right,
    project_story_2_column_layout_image_copy_image,
    project_story_2_column_layout_image_copy_copy,
    project_story_2_column_layout_image_copy_image_position,
    project_story_2_column_layout_image_copy_description,
  } = content
  return acf_fc_layout === "project_story_2_column_layout" ? (
    <div className="project__grid-wrapper">
      <div className="project__col project__col--2">
        <img className="project__image project__image--2" src={project_story_2_column_layout_image_left} alt="Robert Idol Design" />
        <h4 className="project__subtitle">{project_story_2_column_layout_description_left}</h4>
      </div>
      <div className="project__col project__col--2">
        <img className="project__image project__image--2" src={project_story_2_column_layout_image_right} alt="Robert Idol Design" />
        <h4 className="project__subtitle">{project_story_2_column_layout_description_right}</h4>
      </div>
    </div>
  ) : (
    <div className="project__grid-wrapper">
      {project_story_2_column_layout_image_copy_image_position === "Left" ? (
        <>
          <div className="project__col project__col--2">
            <img className="project__image project__image--2" src={project_story_2_column_layout_image_copy_image} alt="Robert Idol Design" />
            <h4 className="project__subtitle">{project_story_2_column_layout_image_copy_description}</h4>
          </div>
          <div className="project__col project__col--2 project__col--2-copy">
            <p className="project__copy">{project_story_2_column_layout_image_copy_copy}</p>
          </div>
        </>
      ) : (
        <>
          <div className="project__col project__col--2 project__col--2-copy">
            <p className="project__copy">{project_story_2_column_layout_image_copy_copy}</p>
          </div>
          <div className="project__col project__col--2">
            <img className="project__image project__image--2" src={project_story_2_column_layout_image_copy_image} alt="Robert Idol Design" />
            <h4 className="project__subtitle">{project_story_2_column_layout_image_copy_description}</h4>
          </div>
        </>
      )}
    </div>
  )
}

export { TwoCol }
