import React from "react"

function OneCol(props) {
  const { content } = props
  const { project_story_1_column_layout_description, project_story_1_column_layout_image } = content
  return (
    <div className="project__grid-wrapper">
      <div className="project__col project__col--1">
        <img className="project__image project__image--1" src={project_story_1_column_layout_image} alt="" />
        <h4 className="project__subtitle">{project_story_1_column_layout_description}</h4>
      </div>
    </div>
  )
}

export { OneCol }
