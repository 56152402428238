import React from "react"
import { graphql, Link } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"
import { getNextPage } from "../utils/getNextPage"

// Custom components
import { Nav } from "../components/Nav"
import { Header } from "../components/Header"
import { Markup } from "../components/Markup"
import { Caret } from "../components/Caret"
import { Footer } from "../components/Footer"

// Handles all Projects (created via a Custom Post Type)
export default function Project(props) {
  const {
    data: {
      wordpressWpProjects: {
        title,
        slug,
        wordpress_id,
        acf: {
          project_story_photography_description,
          project_story_photographer,
          projects_project_details_image,
          project_story_summary_copy,
          project_story_summary_headline,
          project_story_summary_image,
          project_story_summary_image_description,
          project_call_to_action,
        },
      },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      flexibleLayout,
      allWordpressWpProjects: { edges: projectEdges },
      allWordpressPage: { edges },
    },
  } = props

  const nextPage = getNextPage({
    projIds: projectEdges.map(proj => proj.node.wordpress_id),
    projSlugs: projectEdges.map(proj => proj.node.slug),
    wordpressId: wordpress_id,
  })

  function quickNullCheck() {
    if (projects_project_details_image === null || project_story_summary_copy === null || project_story_summary_image === null) {
      return false
    }
    return true
  }

  return (
    quickNullCheck() && (
      <section className="white project">
        <Nav slug={slug} menuItemsSorted={handleMenuItems({ items: edges })} />

        <Header headerHeadline={title.replace("&#038;", "&")}>
          <div className="project__title-container">
            <h3 className="project__title">{project_story_photography_description}</h3>
            <h3 className="project__title">{project_story_photographer}</h3>
          </div>
          <img className="project__hero" src={projects_project_details_image.localFile.childImageSharp.fluid.src} alt={title} />
        </Header>

        <section className="project">
          <div className="project__wrapper">
            <div className="project__banner">
              <div className="project__container">
                <h3 className="project__headline">{project_story_summary_headline}</h3>
                {project_story_summary_copy.map(copy => (
                  <p key={copy.project_story_summary_copy_text} className="project__copy">
                    {copy.project_story_summary_copy_text}
                  </p>
                ))}
              </div>
              <div className="project__container project__container--alt">
                <img className="project__picture" src={project_story_summary_image.localFile.childImageSharp.fluid.src} alt={title} />
                <h4 className="project__subtitle">{project_story_summary_image_description}</h4>
              </div>
            </div>
            <div className="project__grid">
              <Markup layout={flexibleLayout && flexibleLayout.acf.project_story_layout} />
            </div>
          </div>
        </section>

        <section className="cta">
          <div className="cta__wrapper">
            <Link className="cta__container" to={`/${nextPage}`}>
              <h3 className="cta__headline">{project_call_to_action}</h3>
              <Caret />
            </Link>
          </div>
        </section>

        <Footer
          companyName={companyName}
          companyTwitter={companyTwitter}
          companyLinkedIn={companyLinkedIn}
          slug={slug}
          menuItemsSorted={handleMenuItems({ items: edges })}
        />
      </section>
    )
  )
}

export const pageQuery = graphql`
  query($pageSpecificSlug: String!, $pageSpecificId: Int!) {
    wordpressWpProjects(slug: { eq: $pageSpecificSlug }) {
      title
      slug
      wordpress_id
      acf {
        project_story_photographer
        project_story_photography_description
        projects_project_details_image {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        project_story_summary_headline
        project_story_summary_copy {
          project_story_summary_copy_text
        }
        project_story_summary_image {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        project_story_summary_image_description
        project_call_to_action
      }
    }
    flexibleLayout(wordpressId: { eq: $pageSpecificId }) {
      acf {
        project_story_layout {
          acf_fc_layout
          project_story_1_column_layout_description
          project_story_1_column_layout_image
          project_story_2_column_layout_description_left
          project_story_2_column_layout_description_right
          project_story_2_column_layout_image_left
          project_story_2_column_layout_image_right
          project_story_2_column_layout_image_copy_image
          project_story_2_column_layout_image_copy_copy
          project_story_2_column_layout_image_copy_image_position
          project_story_2_column_layout_image_copy_description
          project_story_3_column_layout_description_left
          project_story_3_column_layout_description_middle
          project_story_3_column_layout_description_right
          project_story_3_column_layout_image_left
          project_story_3_column_layout_image_middle
          project_story_3_column_layout_image_right
          project_story_copy_only {
            project_story_copy_only_text
          }
        }
      }
    }
    allWordpressWpProjects(sort: { order: ASC, fields: wordpress_id }) {
      edges {
        node {
          wordpress_id
          slug
        }
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
