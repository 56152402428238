import React from "react"

function CopyCol(props) {
  const { content } = props
  const { project_story_copy_only } = content

  return (
    <div className="project__grid-wrapper project__grid-wrapper--alt">
      {project_story_copy_only.map(copy => (
        <div key={copy.project_story_copy_only_text} className="project__grid-container">
          <p className="project__grid-copy">{copy.project_story_copy_only_text}</p>
        </div>
      ))}
    </div>
  )
}

export { CopyCol }
