function getNextPage({ projIds, projSlugs, wordpressId }) {
  const currentPageId = wordpressId
  const allProjects = {}

  projIds.forEach((item, idx) => {
    allProjects[idx] = projSlugs[idx]
  })

  let nextPageIndex = projIds.indexOf(currentPageId) + 1
  if (nextPageIndex === projIds.length) {
    nextPageIndex = 0
  }
  return allProjects[nextPageIndex]
}

export { getNextPage }
