import React from "react"

function ThreeCol(props) {
  const { content } = props
  const {
    project_story_3_column_layout_image_left,
    project_story_3_column_layout_description_left,
    project_story_3_column_layout_image_middle,
    project_story_3_column_layout_description_middle,
    project_story_3_column_layout_image_right,
    project_story_3_column_layout_description_right,
  } = content
  return (
    <div className="project__grid-wrapper">
      <div className="project__col project__col--3">
        <img className="project__image project__image--3" src={project_story_3_column_layout_image_left} alt="" />
        <h4 className="project__subtitle">{project_story_3_column_layout_description_left}</h4>
      </div>
      <div className="project__col project__col--3">
        <img className="project__image project__image--3" src={project_story_3_column_layout_image_middle} alt="" />
        <h4 className="project__subtitle">{project_story_3_column_layout_description_middle}</h4>
      </div>
      <div className="project__col project__col--3">
        <img className="project__image project__image--3" src={project_story_3_column_layout_image_right} alt="" />
        <h4 className="project__subtitle">{project_story_3_column_layout_description_right}</h4>
      </div>
    </div>
  )
}

export { ThreeCol }
