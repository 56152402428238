import React from "react"

// Custom components
import { OneCol } from "../components/OneCol"
import { TwoCol } from "../components/TwoCol"
import { ThreeCol } from "../components/ThreeCol"
import { CopyCol } from "../components/CopyCol"

function useFlexibleLayout(layout) {
  const layoutNames = layout.map(item => item.acf_fc_layout)
  const markupArr = []
  layout.forEach((item, idx) => {
    switch (layoutNames[idx]) {
      case "project_story_3_column_layout":
        markupArr.push(<ThreeCol key={`${layoutNames}--${[idx]}`} content={item} />)
        break

      case "project_story_2_column_layout":
        markupArr.push(<TwoCol key={`${layoutNames}--${[idx]}`} content={item} />)
        break

      case "project_story_2_column_layout_image_copy":
        markupArr.push(<TwoCol key={`${layoutNames}--${[idx]}`} content={item} />)
        break

      case "project_story_1_column_layout":
        markupArr.push(<OneCol key={`${layoutNames}--${[idx]}`} content={item} />)
        break

      case "project_story_copy_only_layout":
        markupArr.push(<CopyCol key={`${layoutNames}--${[idx]}`} content={item} />)
        break

      default:
        break
    }
  })
  return markupArr
}

export { useFlexibleLayout }
